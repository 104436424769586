<template>
  <div
    id="table-supplier"
    class="table__container"
  >
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="d-flex pl-2">
          <b-img
            class="filter-image"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
            alt="Icon-order-list-down"
            role="button"
            @click="filter.sort_type == 'asc' ? filter.sort_type = 'desc' : filter.sort_type = 'asc'"
          />
        </div>
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input
              v-model="filter.search"
              placeholder="Cari No. Pembayaran atau No. PO"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1 mt-3"
    >
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table
      v-else
      max-height="80vh"
      :columns="columns"
      :rows="items"
      :fixed-header="false"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
        perPage:pageLength
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'" />

        <span v-else-if="props.column.field == 'cetak'">
          <span v-if="checkPermission('detail pembayaran ke supplier')">
            Berita Acara Pembayaran
          </span>
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: NO PO ID -->
        <span v-if="props.column.field === 'po_so_id'">
          <span>{{ props.row.po_so_id || '-' }}</span>
        </span>
        <span v-else-if="props.column.field === 'total'">
          <span>{{ props.row.total | formatAmount }}</span>
        </span>
        <span v-else-if="props.column.field === 'vouchers'">
          <span v-if="(props.row.vouchers && props.row.vouchers.length === 0) || !props.row.vouchers">-</span>
          <div v-for="item, index in props.row.vouchers" :key="index">
            <span>{{ item.voucher_code }} </span>
            <span>({{ item.amount | formatAmount }})</span>
          </div>
        </span>
        <span v-else-if="props.column.field === 'payment_method'">
          <span class="text-capitalize">{{ props.row.payment_method || 'Tempo' }}</span>
        </span>

        <!-- Column: Cetak -->
        <span v-else-if="props.column.field === 'cetak'">
          <div>
            <b-button
              class="bg-white border-8 border-0 px-5 py-1"
              @click="goToCetak(props.row)"
              v-if="checkPermission('detail pembayaran ke supplier')"
            >
              Cetak
            </b-button>
          </div>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="checkPermission('batalkan pembayaran ke supplier')">
            <b-dropdown
              v-if="props.row.payment_type != 'tunai'"
              id="dropdown-dropleft"
              class="d-flex"
              right
            >
              <template
                #button-content
                class="btn-white text-center"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="cancelPayment(props.row.uuid)">
                <span>
                  Cancel Pembayaran
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <div
      v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton, BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BRow,
    BCol,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: 'No. Pembayaran',
          field: 'payment_number',
        },
        {
          label: 'No. PO',
          field: 'po_so_id',
        },
        {
          label: 'Nilai',
          field: 'total',
        },
        {
          label: 'Voucher',
          field: 'vouchers',
        },
        {
          label: 'Supplier',
          field: 'supplier.name',
        },
        {
          label: 'Metode Pembayaran',
          field: 'payment_method',
        },
        {
          label: 'Berita Acara Pembayaran',
          field: 'cetak',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      items: [],
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'desc',
      },
      isLoading: false,
      page: 1,
    }
  },
  watch: {
    filter: {
      handler() {
        this.getData()
      },
      deep: true,
    },
  },
  created() {
    if (checkPermission('list pembayaran ke supplier')) {
      this.getData()
    }
  },
  methods: {
    goToCetak(item) {
      this.$router.push({ name: 'invoice.index', params: { id: item.uuid }, query: { q: 'payment_report', type: 'supplier', payment_type: item.payment_type, pembayaran: 'pemasok', page: this.page } })
    },
    cancelPayment(id) {
      this.$store.dispatch('payout/cancelSupplier', {
        uuid: `${id}/cancel`,
        params: '',
      }).then(() => {
        this.getData()
        successNotification(this, 'Success', 'Pembayaran ke supplier berhasil dibatalkan')
      }).catch(err => {
        console.log(err)
      })
    },
    getData(page = this.$route.query.page || 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      queryParams.for_list_payment = 1
      this.page = page

      this.$store.dispatch('payout/getSupplier', {
        uuid: '',
        params: queryParams,
      }).then(result => {
        this.rows = result.data.data
        this.items = result.data.data.data
        this.isLoading = false
        // console.log(result);
      }).catch(err => {
        if (err.response.data.meta.messages) {
          errorNotification(this, 'Oops!', err.response.data.meta.messages)
        }
        this.isLoading = false
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

#table-supplier {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #ffffff;
  }
  .vgt-table.bordered {
    th {
      vertical-align: middle;
    }
  }
}
</style>
